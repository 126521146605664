import "./App.scss";
import React from "react";
import { connect, useSelector, shallowEqual } from "react-redux";
import { RoutePath } from "./route-paths";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { History } from 'history';
import HomePage from "./pages/home-pages/home-page/home-page";
import { compose } from "redux";
import GameRandomlyMock from "./pages/game-randomly-mock/game-randomly-mock";
import { selectCurrentUser } from "./redux/user/user.selectors";
import HomePageAuth from "./pages/home-pages/home-page-auth/home-page-auth";
import SignInPage from "./pages/home-pages/sign-in-page/sign-in-page";
import PolicyPage from "./pages/home-pages/policy-page/policy-page";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import GamePageWrapper from "./pages/game-page/game-page-wrapper";

interface Props {
  match: any;
  history?: History;
}

const HomePageSwitcherItems: React.FC<Props> = (props: Props) => {
  const user = useSelector(selectCurrentUser, shallowEqual);
  const { token } = user;

  return (
    token ?
      <Switch>
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.POLICY_PAGE} component={PolicyPage} />
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.SIGN_IN_PAGE} render={() => (<Redirect to={RoutePath.HOME_PAGE} />)} />
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.HOME_PAGE} component={HomePageAuth} />
      </Switch>
      :
      <Switch>
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.POLICY_PAGE} component={PolicyPage} />
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.SIGN_IN_PAGE} component={SignInPage} />
        <Route onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.HOME_PAGE} component={HomePage} />
      </Switch>
  )
}

const HomePageSwitcher: React.FC<Props> = (props: Props) => {
  return (
    <>
      <HomePageSwitcherItems {...props} />
      <GameRandomlyMock />
    </>
  )
}

const App: React.FC = () => {

  return (
    <>
      <div id="app-pages" className="app-pages">
        <Switch>
          <Route exact onUpdate={() => window.scrollTo(0, 0)} path={RoutePath.GAME_PAGE} component={GamePageWrapper} />
          <Route component={HomePageSwitcher} />
        </Switch>
      </div>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);