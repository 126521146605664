import { ExtrudeGeometryOptions, MeshPhongMaterialParameters } from "three";

export const normalMicrobeParams = {
  extrudeSettings: {
    steps: 0,
    depth: 0,
    bevelEnabled: true,
    bevelThickness: 0.2,
    bevelSize: 0.2,
    bevelOffset: 0.1,
    bevelSegments: 15,
    curveSegments: 20,
  } as ExtrudeGeometryOptions,

  bodyMaterialParams: {
    color: 0x222222, opacity: 0.5, flatShading: false,
    shininess: 0, transparent: true, depthTest: true,
    morphTargets: true
  } as MeshPhongMaterialParameters,

  coreMaterialParams: {
    color: 0x222222, opacity: 0.5, flatShading: false,
    shininess: 0, transparent: false,
    morphTargets: true
  } as MeshPhongMaterialParameters,
}