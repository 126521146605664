import AppStateTypes from './appState.types';
import { UserMicrobeFrame } from '../../dto/userMicrobeFrame';

export const INITIAL_STATE = {
  frame: {
    microbeType: "normal",
    lifePoints: 50,
    stepX: 0.01,
    stepY: 0.01,
    nextStepTime: Number(Date.UTC),
    servPosition: { x: 3, y: 3 },
    targetPosition: { x: 5, y: 5 }
  } as UserMicrobeFrame,
  codeTop: "",
  codeBottom: ""
}

const appStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case AppStateTypes.SET_CODE_TOP:
      return {
        ...state,
        codeTop: action.payload
      }
    case AppStateTypes.SET_FRAME:
      return {
        ...state,
        frame: {
          microbeType: action.payload.microbeType,
          lifePoints: action.payload.lifePoints,
          stepX: 0.01,
          stepY: 0.01,
          nextStepTime: action.payload.nextStepTime,
          servPosition: { x: action.payload.servPosition.x, y: action.payload.servPosition.y },
          targetPosition: { x: action.payload.targetPosition.x, y: action.payload.targetPosition.y }
        }
      }
    case AppStateTypes.SET_CODE_BOTTOM:
      return {
        ...state,
        codeBottom: action.payload
      }
    default: {
      return state;
    }
  }
}

export default appStateReducer;