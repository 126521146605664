import { ApiRootPath } from "./ApiRootPath";

const rootPath: string = ApiRootPath.rootPath;
export class ApiPath {
  static auth_signUp: string = rootPath + 'auth/signUp';
  static auth_signIn: string = rootPath + 'auth/signIn';
  static auth_signOut: string = rootPath + 'auth/signOut';
  static auth_refreshToken: string = rootPath + 'auth/refreshToken';
  static actions_addFood: string = rootPath + 'Action/AddFood';
  static actions_setTotemTarget: string = rootPath + 'Action/SetTotemTarget';

}