import React, { useState, useEffect } from 'react';

const Stopwatch = () => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [miliseconds, setMiliseconds] = useState(0);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval: any = [, ,];
    if (isActive) {
      interval[0] = setInterval(() => {
        setMinutes(minutes => minutes + 1);
      }, 60000);

      interval[1] = setInterval(() => {
        setSeconds(seconds => (seconds + 1) % 60);
      }, 1000);

      // interval[2] = setInterval(() => {
      //   setMiliseconds(miliseconds => (miliseconds + 1) % 10);
      // }, 100);

    }
    return () => {
      clearInterval(interval[0]);
      clearInterval(interval[1]);
      // clearInterval(interval[2]);
    }
  }, []);

  return (
    <>
      {minutes}:{seconds < 10 ? (seconds === 0 ? '00' : ('0' + seconds)) : seconds}
    </>
  );
};

export default Stopwatch;