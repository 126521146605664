import { Shape } from "three";

export const drawNormalMicrobe = (morphStep: number) => {
  var microbe = new Shape();
  let x = 0, y = 0, size = 0.5, rand = 0;
  if (morphStep === 1) {
    rand = 0.1
    microbe.moveTo(x - size + rand, y);
    microbe.bezierCurveTo(x - size + rand, y + size + rand, x + size, y + (size - (size * 1.8 / 3)), x + size, y);
    microbe.bezierCurveTo(x + size, y - (size - (size * 1.8 / 3)), x - size + rand, y - size - rand, x - size + rand, y);
  }
  else {
    microbe.moveTo(x - size + rand, y);
    microbe.bezierCurveTo(x - size + rand, y + size + rand, x + size, y + (size - (size * 1.8 / 3)), x + size, y);
    microbe.bezierCurveTo(x + size, y - (size - (size * 1.8 / 3)), x - size + rand, y - size - rand, x - size + rand, y);
  }

  return microbe;
}