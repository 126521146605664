import THREE, { Color, Vector2, Raycaster } from "three";
import React, { useRef } from "react";
import { useFrame } from "react-three-fiber";
import { cameraRef } from "../camera/camera";
import { planeRef } from "../foods/plane/gameTerrain";
import { gameFrame } from "../../staticRedux/gameFrames";
import { ActionsController } from "../../api/requests/send-action";
import { PositionDto } from "../../dto/position-dto";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { totemRef } from "./totemGroup";

const Totem: React.FC<any> = (props: any) => {
  const user = useSelector(selectCurrentUser, shallowEqual);
  totemRef[props.id] = useRef<THREE.Mesh>();
  const totemRefCube = useRef<THREE.Mesh>();
  const lightRef = useRef<THREE.PointLight>();
  let isMouseLeftEnabled = true;

  function onDocumentMouseDown(event: MouseEvent) {
    var raycaster = new Raycaster();
    var mouse = new Vector2();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, cameraRef.current);
    var intersects = raycaster.intersectObjects([planeRef.current]);
    let position: PositionDto = { x: intersects[0].point.x, y: intersects[0].point.y };
    console.log(event);
    if (event.which === 3) {
      ActionsController.SetTotemTarget(position);
    }
    else if (event.which === 1 && isMouseLeftEnabled) {
      isMouseLeftEnabled = false;
      ActionsController.AddFood(position);
      setTimeout(() => {
        isMouseLeftEnabled = true;
      }, 150);
    }
    return false;
  }

  if (user.userId === gameFrame.totems[props.id].userId) {
    window.oncontextmenu = function (e: MouseEvent) {
      e.preventDefault();
      onDocumentMouseDown(e)
      return false;
    }

    window.onclick = function (e: MouseEvent) {
      e.preventDefault();
      onDocumentMouseDown(e)
      return false;
    }

    // window.addEventListener("click", function (e: MouseEvent) {
    //   e.preventDefault();
    //   onDocumentMouseDown(e)
    //   return false;
    // });
  }

  useFrame(() => {
    totemRefCube.current!.rotation.z += 0.1;
    if (totemRef[props.id].current && gameFrame.totems[props.id]) {
      totemRef[props.id].current!.position.set(gameFrame.totems[props.id].servPosition.x, gameFrame.totems[props.id].servPosition.y, 0);
    }
  })

  return (
    <>
      <group key={props.id + "totemRef"} ref={totemRef[props.id]} castShadow={false} position={[10 * props.id, 0, 0]}>
        <spotLight
          position={[0, 0, 20]}
          rotation={[0, 0, 0]}
          distance={0}
          angle={0.70}
          decay={1}
          penumbra={0.1}
          color={new Color(1, 1, 1)}
          intensity={user.userId === gameFrame.totems[props.id].userId ? 0.2 : 0}
          target={totemRefCube.current}
        />
        <pointLight
          position={[0, 0, 5]}
          distance={15}
          decay={1.9}
          color={gameFrame.totems[props.id].team === 1 ? new Color(50, 20, 20) : new Color(1, 30, 30)}
          intensity={0.03}
          castShadow={false}
          ref={lightRef}
        />
        <group position={[0, 0, 0.5]} rotation={[0, 0, 0]} ref={totemRefCube}>
          <mesh position={[0, 0, 0]} rotation={[0.615, Math.PI / 4, 0]}>
            {/* 0.615 w rotacji x wynika z tego, że po przekręceniu jednego kąta o 45 stopni, drugi przekręcamy z uwzględneiniem przesunięcia */}
            <boxGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
            <meshNormalMaterial attach="material" transparent={true} opacity={0.5} />
          </mesh>
        </group>
      </group>

    </>
  )
}

export default Totem;