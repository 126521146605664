export function forTab(length: number, width?: number): Array<number> | Array<Array<number>> {
  if (width) {
    let tab: Array<Array<number>> = [];
    for (let i = 0; i < length; i++) {
      tab.push([]);
      for (let j = 0; j < width; j++) {
        tab[i].push(j);
      }
    }
    return tab;
  }
  else {
    let tab: Array<number> = [];
    for (let i = 0; i < length; i++) {
      tab.push(i);
    }
    return tab;
  }
}

export function forTabBoolean(defaultVal: boolean, length: number, width?: number): Array<boolean> | Array<Array<boolean>> {
  if (width) {
    let tab: Array<Array<boolean>> = [];
    for (let i = 0; i < length; i++) {
      tab.push([]);
      for (let j = 0; j < width; j++) {
        tab[i].push(defaultVal);
      }
    }
    return tab;
  }
  else {
    let tab: Array<boolean> = [];
    for (let i = 0; i < length; i++) {
      tab.push(defaultVal);
    }
    return tab;
  }
}