import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Stopwatch from "../stopwatch/stopwatch";
import { HubConnectionBuilder, HubConnection, HubConnectionState } from "@aspnet/signalr";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { Redirect } from "react-router-dom";
import { RoutePath } from "../../route-paths";

interface Props {
  leaveQueueHandler?: any;
}

const QueueTile: React.FC<any> = (props: Props) => {
  const { leaveQueueHandler } = props;

  const [playersCount, setPlayersCount] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const user = useSelector(selectCurrentUser, shallowEqual);
  const { token } = user;
  // let globalConnection: HubConnection;

  const setUpSignalRConnection = async () => {
    const connection = new HubConnectionBuilder()
      .withUrl('https://localhost:44372/queuehub', {
        accessTokenFactory: () => token.replace("Bearer ", "")
      })
      // .withAutomaticReconnect()
      .build();
    connection.on('Message', (message: string) => {
      console.log('Message', message);
    });

    connection.on('PlayersCount', (data: any) => {
      console.log(data);
      setPlayersCount(data);
    });

    connection.on('GameStarts', (data: any) => {
      if (data) {
        setRedirect(true);
      }
    });

    try {
      await connection.start();
    } catch (err) {
      console.log(err);
    }
    return connection;
  };

  const cleanUpSignalRConnection = async (connection: HubConnection) => {
    // connection.invoke('LeaveQueue');
    if (connection.state === HubConnectionState.Connected) {
      connection.off('PlayersCount');
      connection.off('GameStarts');
      connection.stop();
    } else {
      connection.off('PlayersCount');
      connection.off('GameStarts');
      connection.stop();
    }
  };

  useEffect(() => {
    let globalConnection: HubConnection;
    setUpSignalRConnection().then((connection: HubConnection) => {
      globalConnection = connection;
    });

    return () => {
      cleanUpSignalRConnection(globalConnection)
    };
  }, [])

  return (
    <>
      {redirect ? <Redirect to={RoutePath.GAME_PAGE} /> : null}
      <div className="queue-tile">
        <div className="cancel-btn" onClick={leaveQueueHandler}>&#215;</div>
        <div className="queue-title">Finding players </div>
        <div className="row">
          <div className="col50">
            <div className="subtitle">
              Queue
          </div>
            <div className="subContent">
              <Stopwatch />
            </div>
          </div>
          <div className="col50">
            <div className="subtitle">
              Players
            </div>
            <div className="subContent">
              {playersCount}/2
          </div>
          </div>
        </div>
        <div className="expected-time"></div>
      </div>
    </>
  )
}

export default QueueTile;