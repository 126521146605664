import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./../home-page.scss";
import SignInForm from "../home-pages-forms/sign-upin-form/sign-in-form";
import { RoutePath } from "../../../route-paths";

const SignInPage: React.FC<any> = (props: any & RouteComponentProps) => {
  return (
    <div className="home-page">
      <div className="box">
        <div className="title">
          Sign in
        </div>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiam minima molestias odio maiores libero natus cumque amet impedit repellendus.
        </p>
        <SignInForm />
        <div className="sign-inup-nav">
          <Link to={RoutePath.HOME_PAGE}>Forget Password</Link>
          <br />
          <br/>
          Don't have account? <Link to={RoutePath.HOME_PAGE}>Sign Up</Link>
        </div>
      </div>
    </div>
  )
}

export default SignInPage;