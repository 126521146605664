import "./game-page.scss";
import React, { useEffect } from "react";
import { Canvas } from 'react-three-fiber'
import GamePage from "./game-page";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { HubConnectionBuilder, HubConnection } from "@aspnet/signalr";
import { UserMicrobeFrame } from "../../dto/userMicrobeFrame";
import { gameFrame } from "../../staticRedux/gameFrames";
import Camera from "../../components/camera/camera";
import { TotemFrame } from "../../dto/totem-frame";

const GamePageWrapper: React.FC<any> = () => {

  const user = useSelector(selectCurrentUser, shallowEqual);
  const { token } = user;

  const setUpSignalRConnection = async () => {
    const connection = new HubConnectionBuilder()
      .withUrl('https://localhost:44372/gamehub', {
        accessTokenFactory: () => token.replace("Bearer ", "")
      })
      .withAutomaticReconnect()
      .build();

    connection.on('GameState', (gameState: any) => {
      gameState.microbes.map((microbe: any, index: number) => {
        let microbeClient = {
          microbeType: microbe.microbeType,
          lifePoints: microbe.lifePoints,
          stepX: microbe.stepX,
          stepY: microbe.stepY,
          nextStepTime: microbe.nextStepTime,
          servPosition: { x: microbe.position.x, y: microbe.position.y },
          targetPosition: { x: microbe.targetPosition.x, y: microbe.targetPosition.y }//{ x: (Math.random() - 0.4) * 30, y: (Math.random() - 0.3) * 20 }
        } as UserMicrobeFrame;
        gameFrame.microbes[index] = microbeClient
      })
      
      gameFrame.food = gameState.food.map((food: any, index: number) => {
        return {
          microbeType: food.microbeType,
          lifePoints: food.lifePoints,
          stepX: food.stepX,
          stepY: food.stepY,
          nextStepTime: food.nextStepTime,
          servPosition: { x: food.position.x, y: food.position.y },
          targetPosition: { x: food.targetPosition.x, y: food.targetPosition.y }
        } as UserMicrobeFrame
      });

      gameState.totems.map((totem: any, index: number) => {
        gameFrame.totems[index] = {
          userId: totem.userId,
          lifePoints: totem.lifePoints,
          team: totem.team,
          stepX: totem.stepX,
          stepY: totem.stepY,
          nextStepTime: totem.nextStepTime,
          servPosition: { x: totem.position.x, y: totem.position.y },
          targetPosition: { x: totem.targetPosition.x, y: totem.targetPosition.y }
        } as TotemFrame
      });
    });

    try {
      await connection.start();
    } catch (err) {
      // console.log(err);
    }
    return connection;
  };

  const cleanUpSignalRConnection = async (
    connection: HubConnection,
  ) => {
    if (connection) {
      connection.off('GameState');
      connection.stop();
    }
  };
  let flag = false;
  useEffect(() => {
    let connection: HubConnection;
    if (flag === false) {

      setUpSignalRConnection().then(con => {
        connection = con;
      });
      flag = true;
    }
    return function cleanUp() {
      cleanUpSignalRConnection(connection);
    };
  }, []);

  return (
    <>
      <div className="game-page">
        <Canvas id="canvas">
          <Camera />
          <GamePage />
        </Canvas>
      </div>
    </>
  )
}

export default GamePageWrapper;