import { Mesh, Group, SphereGeometry, MorphTarget } from "three";
import React, { MutableRefObject, useRef } from "react";
import { useFrame } from "react-three-fiber";
import { normalFoodParams } from "./normalFoodParams";
import { gameFrame } from "../../../staticRedux/gameFrames";
import { foodRef } from "./normalFoodGroup";

const NormalFood: React.FC<any> = (props: any) => {
  foodRef[props.id] = useRef<THREE.Mesh>();
  let coreMicrobeMorphGeom = new SphereGeometry(0.13, 10, 10);

  return (
    <>
      <group key={props.id + "groupRef"} ref={foodRef[props.id]}>
        <mesh key={props.id + "microbeCore"} morphTargetInfluences={[1]} position={[0, 0, -1]}>
          <sphereGeometry attach="geometry" args={[0.15, 10, 10]}
            morphTargets={[{ vertices: coreMicrobeMorphGeom.vertices }] as Array<MorphTarget>}
          />
          <meshPhongMaterial attach="material" args={[normalFoodParams.coreMaterialParams]} />
        </mesh>
      </group>
    </>
  )
}

export default NormalFood;