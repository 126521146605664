import { store } from "../redux/store";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "./ApiMethodDto";
import UserActionTypes from "../redux/user/user.types";

export type CallApiResponse = {
  data: any,
  details: Response
};

export async function callApi(method: string, path: string, options: ApiMethodDto): Promise<CallApiResponse> {
  
  const query = convertParamsToQuery(options.params);
  const res = await fetch(path + query, {
    method,
    headers: options.headers,
    body: options.datatype === "FormData" && options.body ? options.body : (options.body ? JSON.stringify(options.body) : null)
  })

  let returnRes: any;

  try {
    returnRes = await res.json()
  }
  catch{
    returnRes = await res;
  }

  if (Array.isArray(options.expectedStatus) && !(options.expectedStatus.indexOf(await res.status) === -1)) {
    return { data: returnRes, details: await res };
  }
  else if (await res.status === options.expectedStatus) {
    return { data: returnRes, details: await res };
  }

  if (returnRes.status === 204) {
     throw returnRes.status;
  }
  
  else if (await res.status === 401) {
    const unauthorizedToast = document.getElementsByClassName('unauthorizedToast');
    if (unauthorizedToast.length === 0) {
      ToastsStore.error('Error ' + await res.status + ': ' + returnRes.error, undefined, 'unauthorizedToast');
    }
    store.dispatch({ type: UserActionTypes.SIGN_OUT_SUCCESS });
    throw (returnRes.error)
  }
  else if (returnRes.error !== undefined) ToastsStore.error('Error ' + await res.status + ': ' + returnRes.error);
  else ToastsStore.error('Something went wrong. Api-call-service: 29, ' + await res);
  throw returnRes.error
}

function convertParamsToQuery(params?: Array<UrlParam> | UrlParam): string {
  let query: string = '';
  if (params) {
    query = "?"
    if (Array.isArray(params)) {
      const paramList = params as unknown as Array<UrlParam>
      paramList.map((param: UrlParam, index: number) => {
        query += param.name + "=" + param.value.toString() + (index < paramList.length ? "&" : "");
        return true;
      });
      query = query.substring(0, query.length-1);
    }
    else {
      const param = params as unknown as UrlParam
      query += param.name + "=" + param.value.toString();
    }
  }
  return query;
}