import React, { useRef, MutableRefObject } from "react";
import "./game-page.scss";
import { Color, Group } from "three";

import NormalFoodGroup from "../../components/foods/normalFood/normalFoodGroup";
import NormalMicrobeGroup from "../../components/microbes/normalMicrobe/normalMicrobeGroup";
import FogOfWar from "../../components/fog-of-war/fog-of-war";
import GameTerrain from "../../components/foods/plane/gameTerrain";
import TotemGroup from "../../components/totem/totemGroup";
import { Provider } from "react-redux";
import { store } from "../../redux/store";

const GamePage: React.FC<any> = () => {
  const foodContainerRef: MutableRefObject<Group | undefined> = useRef<THREE.Group>();

  return (
    <>
      <ambientLight args={[0xffffff, 0.05]} />
      <directionalLight intensity={0.005} color={new Color(255, 255, 255)} position={[0, 0, 150]} />
      <GameTerrain />
      <group ref={foodContainerRef} />
      <NormalMicrobeGroup />
      <NormalFoodGroup />
      <Provider store={store}>
        <TotemGroup />
        <FogOfWar />
      </Provider>
    </>
  )
}

export default GamePage;