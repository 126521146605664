import React, { useState } from "react";
import './game-launcher.scss'
import BtnCustomShadow from "../btn-custom-shadow/btn-custom-shadow";
import QueueTile from "../queueTile/queueTile";

const GameLauncher: React.FC<any> = () => {
  const [isQueueActive, setIsQueueActive] = useState(false);
  return (
    <div className="game-launcher">
      <BtnCustomShadow disabled={isQueueActive} onClick={() => setIsQueueActive(true)}>Play</BtnCustomShadow>
      {isQueueActive ?
        <QueueTile leaveQueueHandler={() => setIsQueueActive(false)} />
        : null}
    </div>
  )
}

export default GameLauncher;