import { PositionDto } from "../dto/position-dto";

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getDistanceBetweenPoints(pointOne: PositionDto, pointTwo: PositionDto) {
  return Math.sqrt(Math.pow(Math.abs(pointOne.x - pointTwo.x), 2) + Math.pow(Math.abs(pointOne.y - pointTwo.y), 2));
}