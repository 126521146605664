import React, { useRef, useEffect } from "react";
import { useFrame, useThree } from 'react-three-fiber'
import { Vector2 } from "three";
import { addEvent } from "../../gameTools/eventsInjector";

export let cameraRef: any;

const Camera = () => {
  cameraRef = useRef();
  let mouseToWindow = new Vector2();
  let isMouseOut: any = false;

  function onDocumentMove(event: MouseEvent) {
    mouseToWindow.x = event.clientX;
    mouseToWindow.y = event.clientY;
  }

  addEvent(document, "mouseout", function (e: any) {
    e = e ? e : window.event;
    var from = e.relatedTarget || e.toElement;
    if (!from || from.nodeName === "HTML") {
      isMouseOut = true;
    }
  });

  addEvent(document, "wheel", function (e: any) {
    if(e.deltaY < 0 && cameraRef.current!.position.z > 20 ){
      cameraRef.current!.position.z -= 1;
    }
    if(e.deltaY > 0 && cameraRef.current!.position.z < 50 ){
      cameraRef.current!.position.z += 1;
    }
  });

  addEvent(document, "mouseover", function (e: any) {
    e = e ? e : window.event;
    var from = e.relatedTarget || e.toElement;
    if (from || from.nodeName !== "HTML") {
      isMouseOut = false;
    }
  });
  
  window.addEventListener("mousemove", onDocumentMove);
  const { setDefaultCamera } = useThree()
  useEffect(() => void setDefaultCamera(cameraRef.current), [])
  const speedOfMove = 0.8

  useFrame(() => {
    if (false) {
      if (Math.abs(mouseToWindow.x - window.innerWidth) < 30 || (isMouseOut && Math.abs(mouseToWindow.x - window.innerWidth) < 250)) {
        if (cameraRef.current!.position.x < 200)
          cameraRef.current!.position.x += speedOfMove;
      }
      if (mouseToWindow.x < 30 || (isMouseOut && mouseToWindow.x < 250)) {
        if (cameraRef.current!.position.x > -200)
          cameraRef.current!.position.x -= speedOfMove;
      }
      if (Math.abs(mouseToWindow.y - window.innerHeight) < 30 || (isMouseOut && Math.abs(mouseToWindow.y - window.innerHeight) < 250)) {
        if (cameraRef.current!.position.y > -200)
          cameraRef.current!.position.y -= speedOfMove;
      }
      if (mouseToWindow.y < 30 || (isMouseOut && mouseToWindow.y < 250)) {
        if (cameraRef.current!.position.y < 200)
          cameraRef.current!.position.y += speedOfMove;
      }
      cameraRef.current.updateMatrixWorld()
    }
  })
  return <perspectiveCamera ref={cameraRef} position={[0, 0, 50]} />
}

export default Camera;
