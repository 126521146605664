import { Mesh, Group, ExtrudeGeometry, SphereGeometry, MorphTarget } from "three";
import { UserMicrobeFrame } from "../../../dto/userMicrobeFrame";
import React, { MutableRefObject, useRef } from "react";
import { useFrame } from "react-three-fiber";
import { drawNormalMicrobe } from "./normalMicrobeMockSkeleton";
import { normalMicrobeParams } from "./normalMicrobeMockParams";
import { getDistanceBetweenPoints } from "../../../gameTools/randomInt";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

const angle = (cx: number, cy: number, ex: number, ey: number) => {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx);
  return theta
}

const NormalMicrobeMock: React.FC<any> = (props: any) => {

let tempFrame =
  {
    microbeType: "normal",
    lifePoints: 50,
    stepX: 0.001,
    stepY: 0.001,
    servPosition: { x: 0, y: 0 },
    targetPosition: { x: 0, y: 0 }
  } as UserMicrobeFrame;

let gameFrame =
  {
    microbeType: "normal",
    lifePoints: 60,
    nextStepTime: (new Date().getTime() + getRandomInt(500) + 5000),
    servPosition: { x: getRandomInt(10) - 3, y: getRandomInt(8) - 4 },
    targetPosition: { x: getRandomInt(10) - 3, y: getRandomInt(8) - 4 },
  } as UserMicrobeFrame

  const microbeBody: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();
  const microbeCore: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();
  const groupRef: MutableRefObject<Group | undefined> = useRef<THREE.Group>();
  const reffPoint: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();

  let microbeShape = drawNormalMicrobe(0);
  let microbeShapeMorph = drawNormalMicrobe(1);
  let microbeShapeMorphGeom = new ExtrudeGeometry([microbeShapeMorph], normalMicrobeParams.extrudeSettings);
  let coreMicrobeMorphGeom = new SphereGeometry(0.13, 10, 10);
  let slowerAnim = 0;
  let thisMicrobeGameFrame = gameFrame ? gameFrame : tempFrame;
  let maxDistanceBetweenX = thisMicrobeGameFrame.targetPosition.x - thisMicrobeGameFrame.servPosition.x;
  let maxDistanceBetweenY = thisMicrobeGameFrame.targetPosition.y - thisMicrobeGameFrame.servPosition.y;
  let saveOfThisMicrobeGameFrameServPosition = { x: thisMicrobeGameFrame.servPosition.x, y: thisMicrobeGameFrame.servPosition.y };
  let startTime = new Date().getTime();
  let maxExpectedDeltaTime = thisMicrobeGameFrame.nextStepTime - startTime;

  useFrame(() => {
    if (microbeBody.current && microbeCore.current && microbeBody.current!.morphTargetInfluences && microbeCore.current!.morphTargetInfluences && microbeCore.current!.morphTargetInfluences.length > 0 && microbeBody.current!.morphTargetInfluences.length > 0) {
      microbeBody.current!.morphTargetInfluences[0] = (Math.cos((slowerAnim / 15) * Math.PI * ((3 / 10) + 1)) + 1) / 2;
      microbeCore.current!.morphTargetInfluences[0] = (Math.cos((slowerAnim / 15) * Math.PI * ((3 / 10) + 1)) + 1) / 2;
    }

    let deltaTime = Math.abs(startTime - (new Date().getTime()));
    let finishedPartOfTime = deltaTime / maxExpectedDeltaTime;

    if (microbeBody.current && microbeCore.current) {

      if (finishedPartOfTime <= 1 && !(Number.isNaN(finishedPartOfTime) || thisMicrobeGameFrame.nextStepTime < new Date().getTime())) {
        thisMicrobeGameFrame.servPosition.x = saveOfThisMicrobeGameFrameServPosition.x + (maxDistanceBetweenX * finishedPartOfTime);
        thisMicrobeGameFrame.servPosition.y = saveOfThisMicrobeGameFrameServPosition.y + (maxDistanceBetweenY * finishedPartOfTime);
        reffPoint.current!.position.set(thisMicrobeGameFrame.targetPosition.x, thisMicrobeGameFrame.targetPosition.y, 0);
        groupRef.current!.position.set(thisMicrobeGameFrame.servPosition.x, thisMicrobeGameFrame.servPosition.y, 0);
        groupRef.current!.rotation.z = angle(thisMicrobeGameFrame.servPosition.x, thisMicrobeGameFrame.servPosition.y, thisMicrobeGameFrame.targetPosition.x, thisMicrobeGameFrame.targetPosition.y)
      }

      if (Number.isNaN(finishedPartOfTime) || thisMicrobeGameFrame.nextStepTime < new Date().getTime() || (finishedPartOfTime > 1 && gameFrame && thisMicrobeGameFrame.targetPosition.x !== gameFrame.targetPosition.x && thisMicrobeGameFrame.targetPosition.y !== gameFrame.targetPosition.y)) {
        gameFrame = {
          microbeType: "normal",
          lifePoints: 60,
          nextStepTime: (new Date().getTime() + getRandomInt(1000) + 2000),
          servPosition: { x: thisMicrobeGameFrame.targetPosition.x, y: thisMicrobeGameFrame.targetPosition.y },
          targetPosition: { x: getRandomInt(25) - 7, y: getRandomInt(14) - 7 },
        } as UserMicrobeFrame
        
        gameFrame.nextStepTime = new Date().getTime() + (getDistanceBetweenPoints(gameFrame.servPosition, gameFrame.targetPosition) * 250)
        thisMicrobeGameFrame = gameFrame;
        if (thisMicrobeGameFrame) {
          maxDistanceBetweenX = thisMicrobeGameFrame.targetPosition.x - thisMicrobeGameFrame.servPosition.x;
          maxDistanceBetweenY = thisMicrobeGameFrame.targetPosition.y - thisMicrobeGameFrame.servPosition.y;
          startTime = new Date().getTime();
          maxExpectedDeltaTime = thisMicrobeGameFrame.nextStepTime - startTime;
          saveOfThisMicrobeGameFrameServPosition = { x: thisMicrobeGameFrame.servPosition.x, y: thisMicrobeGameFrame.servPosition.y };
        }
      }
      slowerAnim++;
      microbeBody.current!.updateMatrix();
    }
  })



  return (
    <>
      <mesh key={props.id + "reffPoint"} ref={reffPoint} position={[tempFrame.targetPosition.x, tempFrame.targetPosition.y, 0]}>
        <sphereGeometry attach="geometry" args={[0.15, 10, 10]} />
        <meshNormalMaterial attach="material" />
      </mesh>
      <group key={props.id + "groupRef"} ref={groupRef}>
        <mesh key={props.id + "microbeCore"} ref={microbeCore} morphTargetInfluences={[1]} position={[0, 0, 0]}>
          <sphereGeometry attach="geometry" args={[0.15, 10, 10]}
            morphTargets={[{ vertices: coreMicrobeMorphGeom.vertices }] as Array<MorphTarget>}
          />
          <meshPhongMaterial attach="material" args={[normalMicrobeParams.coreMaterialParams]} />
        </mesh>
        <mesh key={props.id + "microbeBody"} ref={microbeBody} morphTargetInfluences={[Math.random()]} position={[0, 0, 0]}>
          <extrudeGeometry
            attach="geometry"
            args={[[microbeShape], normalMicrobeParams.extrudeSettings]}
            morphTargets={[{ vertices: microbeShapeMorphGeom.vertices }] as Array<MorphTarget>}
          />
          <meshPhongMaterial attach="material" args={[normalMicrobeParams.bodyMaterialParams]} />
        </mesh>
      </group>
    </>
  )
}

export default NormalMicrobeMock;