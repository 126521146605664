import { MeshPhongMaterialParameters, ExtrudeGeometryOptions } from "three";

export const gamePageParams = {
  planeMaterialParamsOverlay: {
    color: 0x3E4164,
    shininess: 1,
    castShadow: true,
    reciveShadow: false
  } as MeshPhongMaterialParameters,

  planeMaterialParams: {
    color: 0x383e5f,// 0x222440,
    shininess: 0,
    reciveShadow: false
  } as MeshPhongMaterialParameters,

  planeOverlayExtrudeSettings: {
    steps: 0,
    depth: 0,
    bevelEnabled: false,
  } as ExtrudeGeometryOptions,
}