import THREE, { Mesh, Color, Vector2, Raycaster } from "three";
// import { UserMicrobeFrame } from "../../../dto/userMicrobeFrame";
import React, { MutableRefObject, useRef } from "react";
import { useFrame } from "react-three-fiber";
import { cameraRef, planeRef } from "../game-randomly-mock";

var raycaster = new Raycaster();
var mouse = new Vector2(); //Needed for mouse coordinates
var gameRandomlyMockCanvas = document.getElementById('gameRandomlyMockCanvas');

function onDocumentMouseDown(event: any) {
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  raycaster.setFromCamera(mouse, cameraRef.current);
  var intersects = raycaster.intersectObjects([planeRef.current]);

  if (intersects.length > 0) {
    expectedTotemPosition.stepX = (intersects[0].point.x - totemRef.current!.position.x) / 25
    expectedTotemPosition.stepY = (intersects[0].point.y - totemRef.current!.position.y) / 25

    expectedTotemPosition.x = intersects[0].point.x;
    expectedTotemPosition.y = intersects[0].point.y;
  }
}

let totemRef: MutableRefObject<Mesh | undefined>
let expectedTotemPosition: { x: number, y: number, stepX: number, stepY: number } = { x: 0, y: 0, stepX: 0.1, stepY: 0.1 }
const TotemMock: React.FC<any> = (props: any) => {
  totemRef = useRef<THREE.Mesh>();
  const totemRefCube = useRef<THREE.Mesh>();
  gameRandomlyMockCanvas = document.getElementById('gameRandomlyMockCanvas');
  gameRandomlyMockCanvas!.addEventListener('click', onDocumentMouseDown, false);

  useFrame(() => {
    totemRefCube.current!.rotation.z += 0.1;

    if (Math.abs(expectedTotemPosition.y - totemRef.current!.position.y) >= Math.abs(expectedTotemPosition.stepY)) {
      totemRef.current!.position.y += (expectedTotemPosition.stepY);
    }
    else {
      totemRef.current!.position.y = expectedTotemPosition.y;
    }

    if (Math.abs(expectedTotemPosition.x - totemRef.current!.position.x) >= Math.abs(expectedTotemPosition.stepX)) {
      totemRef.current!.position.x += (expectedTotemPosition.stepX);
    }
    else {
      totemRef.current!.position.x = expectedTotemPosition.x;
    }
  })

  return (
    <>
      <group key={props.id + "totemRef"} ref={totemRef} >
        <spotLight
          position={[0, 0, 20]}
          rotation={[0, 0, 0]}
          distance={0}
          angle={0.70}
          decay={1}
          penumbra={0.1}
          color={new Color(1, 1, 1)}
          intensity={0.95}
          target={totemRefCube.current}
        />
        <pointLight
          position={[0, 0, 0.5]}
          distance={50}
          color={new Color(1, 30, 30)}
          intensity={0.04}
        />
        <group position={[0, 0, 0]} rotation={[0, 0, 0]} ref={totemRefCube}>
          <mesh position={[0, 0, 0]} rotation={[0.615, Math.PI / 4, 0]}>
            <boxGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
            <meshNormalMaterial attach="material" transparent={true} opacity={0.5} />
          </mesh>
        </group>
      </group>

    </>
  )
}

export default TotemMock;