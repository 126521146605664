import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./../home-page.scss";
import { RoutePath } from "../../../route-paths";
import SignUpForm from "../home-pages-forms/sign-upin-form/sign-up-form";

const HomePage: React.FC<any> = (props: any & RouteComponentProps) => {
  return (
    <div className="home-page">
      <div className="box">
        <div className="title">
          World of Microbes
        </div>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiamamet impedit repellendus.
        </p>
        <SignUpForm />
        <div className="sign-inup-nav">
          Already registered: <Link to={RoutePath.SIGN_IN_PAGE}>Sign In</Link>
        </div>

      </div>
    </div>
  )
}

export default HomePage;