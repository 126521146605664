import React, { useState } from "react";
import { useFrame } from "react-three-fiber";
import { gameFrame } from "../../../staticRedux/gameFrames";
import { UserMicrobeFrame } from "../../../dto/userMicrobeFrame";
import NormalMicrobe from "./normalMicrobe";

const NormalMicrobeGroup: React.FC<any> = () => {
  const [microbes, setMicrobes] = useState([] as Array<UserMicrobeFrame>);
  const [microbesCount, setMicrobesCount] = useState(0);

  useFrame(() => {
    if (microbesCount !== gameFrame.microbes.length) {
      setMicrobes(gameFrame.microbes);
      setMicrobesCount(gameFrame.microbes.length);
    }
  })

  return (
    <>
      {microbes.map((value: any, index: number) => {
        return (
          <NormalMicrobe key={index} id={index} />
        )
      })}
    </>
  )
}

export default NormalMicrobeGroup;