import React, { useState, MutableRefObject } from "react";
import { useFrame } from "react-three-fiber";
import { TotemFrame } from "../../dto/totem-frame";
import Totem from "./totem";
import { gameFrame } from "../../staticRedux/gameFrames";
import { Mesh } from "three";

export let totemRef: Array<MutableRefObject<Mesh | undefined>> = []

const TotemGroup: React.FC<any> = () => {
  const [totems, setTotems] = useState([] as Array<TotemFrame>);
  const [totemsCount, setTotemsCount] = useState(0);

  useFrame(() => {
    if (totemsCount !== gameFrame.microbes.length) {
      setTotems(gameFrame.totems);
      setTotemsCount(gameFrame.microbes.length);
    }
  })

  return (
    <>
      {totems.map((value: any, index: number) => {
        return (
          <Totem key={index} id={index} />
        )
      })}
    </>
  )
}

export default TotemGroup;