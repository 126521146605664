import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import "./sign-upin-form.scss";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { signIn } from "../../../../redux/user/user.actions";
import FormInputOval from "../../../../components/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../../../components/btn-custom-shadow/btn-custom-shadow";
import { SignInDto } from "../../../../dto/signInDto";


let timeoutContainerSignInForm: any = undefined;

const SignInForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disableButton, setdisableButton] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(signIn({ email: email, password: password } as SignInDto));
    setdisableButton(true);

    timeoutContainerSignInForm = window.setTimeout(() => {
      setdisableButton(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutContainerSignInForm);
    };
  },[])

  return (
    <form className="sign-upin-form sign-up-email-form" onSubmit={handleSubmit} >
      <FormInputOval
        name="email"
        type="email"
        label="Email"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
        required
      />
      <FormInputOval
        name="password"
        type="password"
        label="Password"
        value={password}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPassword(e.target.value)}
        required
      />
      <BtnCustomShadow type="submit" disabled={(user && user.token) || disableButton}>PLAY</BtnCustomShadow>
    </form >
  )
}

export default SignInForm;