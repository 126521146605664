import React, { MutableRefObject } from "react";
import NormalFood from "./normalFood";
import { Mesh } from "three";
import { forTab } from "../../../gameTools/forTab";
import { useFrame } from "react-three-fiber";
import { gameFrame } from "../../../staticRedux/gameFrames";

export let foodRef: Array<MutableRefObject<Mesh | undefined>> = []
const NormalFoodGroup: React.FC<any> = () => {
  useFrame(() => {
    for (let i = 0; i < gameFrame.food.length && i < 500; i++) {
      foodRef[i].current!.position.set(gameFrame.food[i].servPosition.x, gameFrame.food[i].servPosition.y, 1);
    }
    for (let i = gameFrame.food.length; i < 500; i++) {
      foodRef[i].current!.position.set(0, 0, -1);
    }
  })

  return (
    <>
      {(forTab(500) as Array<number>).map((value: any, index: number) => {
        return (
          <NormalFood key={index} id={index} />
        )
      })}
    </>
  )
}

export default NormalFoodGroup;