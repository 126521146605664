import React, { ReactChild } from "react";
import "./btn-custom-shadow.scss";

interface Props {
  icon?: string
  children: ReactChild
}

const BtnCustomShadow: React.FC<any> = (props: Props) => {
  const { icon, children} = props

  return (
    <button className="customButtonShadow" {...props}>
      <div>{children}</div>
      {icon ? <img src={icon} alt="icon"/> : null}
    </button>
  )
}

export default BtnCustomShadow;