import { takeLatest, put, all, call, select } from "redux-saga/effects";
import UserActionTypes from "./user.types";
import { SignUpDto } from "../../dto/sign-up-dto";
import { ToastsStore } from "react-toasts";
import { UserDto } from "../../dto/user-dto";
import { SignInDto } from "../../dto/signInDto";
import { ApiMethodDto } from "../../api/ApiMethodDto";
import { callApi } from "../../api/ApiCallService";
import { ApiPath } from "../../api/ApiPath";
import { RoutePath } from "../../route-paths";

function* signUpAsync(credentials: any) {
  const options = {
    body: credentials.payload as SignUpDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 201
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signUp, options);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        userId: res.data.userId,
        email: credentials.payload.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Number(Date.UTC),
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd,
        login: res.data.login
      } as UserDto
    });
    ToastsStore.success('Sign Up Success');

  } catch (error) {
    yield put({ type: UserActionTypes.AUTH_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* signInAsync(credentials: any) {
  const options = {
    body: credentials.payload as SignInDto,
    headers: { "Content-Type": "application/json" },
    method: "POST",
    expectedStatus: 200
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "post", ApiPath.auth_signIn, options);

    yield put({
      type: UserActionTypes.SIGN_IN_SUCCESS,
      payload: {
        userId: res.data.userId,
        email: credentials.payload.email,
        roles: res.data.roles.map((item: any) => { return item.role }),
        token: "Bearer " + res.data.token.toString(),
        lastRequestTime: Date.UTC,
        expiresStart: res.data.expiresStart,
        expiresEnd: res.data.expiresEnd,
        login: res.data.login
      }
    });
    ToastsStore.success('Sign In Success');
  } catch (error) {
    yield put({ type: UserActionTypes.SIGN_IN_FAILURE });
    console.error('Something went wrong. ', error)
  }
}

function* refreshTokenAsync() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: '',
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto;

    const res = yield call(callApi, "get", ApiPath.auth_refreshToken, options);

    yield put({
      type: UserActionTypes.TOKEN_REFRESH_SUCCESS,
      payload: { token: res.data.token }
    });

  } catch (error) {
    yield put({ type: UserActionTypes.TOKEN_REFRESH_FAILURE, message: 'Something went wrong.' });
    console.error('Something went wrong. ', error)
  }
}

function* signOutAsync() {
  const token = yield select(state => state.user.token);

  try {
    const options = {
      body: null,
      headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      method: "GET",
      expectedStatus: 200
    } as ApiMethodDto

    yield call(callApi, "get", ApiPath.auth_signOut, options);
    yield put({ type: UserActionTypes.SIGN_OUT_SUCCESS });
    ToastsStore.success('Sign Out Success');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchTokenRefresh() {
  yield takeLatest(UserActionTypes.REFRESH_TOKEN as any, refreshTokenAsync);
}

function* watchSignUp() {
  yield takeLatest(UserActionTypes.AUTH_SIGNUP as any, signUpAsync);
}

function* watchSignIn() {
  yield takeLatest(UserActionTypes.SIGN_IN as any, signInAsync);
}

function* watchSignOut() {
  yield takeLatest(UserActionTypes.SIGN_OUT as any, signOutAsync);
}

export default function* userSagas() {
  yield all([call(watchSignUp), call(watchSignIn), call(watchTokenRefresh), call(watchSignOut)]);
}
