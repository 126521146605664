import React, { useRef, useEffect } from "react";
import "./game-randomly-mock.scss";
import { Canvas, useFrame, useThree } from 'react-three-fiber'
import { gamePageParams } from "./gamePageParams";
import TotemMock from "./totemMock/totemMock";
import NormalMicrobeMock from "./normalMicrobeMock/normalMicrobeMock";

export let cameraRef: any;
export let planeRef: any;

function Camera(props: any) {
  cameraRef = useRef();
  const { setDefaultCamera } = useThree()
  useEffect(() => void setDefaultCamera(cameraRef.current), [])
  useFrame(() => cameraRef.current.updateMatrixWorld())
  return <perspectiveCamera ref={cameraRef} {...props} />
}

const GameRandomlyMock: React.FC = () => {
  planeRef = useRef();

  return (
    <div className="background-mock">
      <div className="game-randomly-mock">
        <Canvas id="gameRandomlyMockCanvas">
          <Camera position={[0, 0, 40]} />
          <ambientLight args={[0xffffff, 0.9]} />
          <mesh position={[0, 0, -1]} ref={planeRef}>
            <boxGeometry attach="geometry" args={[100, 100, 0.1]} />
            <meshPhongMaterial attach="material" args={[gamePageParams.planeMaterialParams]} />
          </mesh>
          <NormalMicrobeMock key={0} id={0} />
          <NormalMicrobeMock key={1} id={1} />
          <NormalMicrobeMock key={2} id={2} />
          <TotemMock id={"1"} />
        </Canvas>
      </div>
    </div>
  )
}
export default React.memo(GameRandomlyMock);