import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import "./../home-page.scss";
import "./policy-page.scss";

const PolicyPage: React.FC<any> = (props: any & RouteComponentProps) => {
  return (
    <div className="home-page policy-page">
      <div className="box">
        <div className="back-button">
          <Link to={RoutePath.HOME_PAGE}><span className="link-arrow">&larr;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Home page</Link>
        </div>
        <div className="title">
          Policy
        </div>
        <h4>
          1. Example subheader
        </h4>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiam minima molestias odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehenderit, porro voluptates perferendis odit eaque recusandae! Itaque error aliquid, eum atque excepturi reiciendis fuga cumque tenetur dolore! Rerum, atque.
        </p>
        <h4>
          2. Example subheader
        </h4>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiam minima molestias odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehenderit, porro voluptates perferendis odit eaque recusandae! Itaque error aliquid, eum atque excepturi reiciendis fuga cumque trum, atque.
        </p>
        <ul>
          <li>Lorem rerum, atque</li>
          <li>Ipsum dolorisum</li>
          <li>Dolor fuga cmq</li>
          <li>Sit emt odit perferendis</li>
        </ul>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiam mitaque error aliquid, eum atque excepturi reiciendis fuga cumque tenem, atque.odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehe
        </p>
        <h4>
          3. Example subheader
        </h4>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae doloribus dicta earum voluptatem, tempore quae tenetur neque accusamus dolorum aperiam minimaa molestias
        </p>
        <p>
          odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehenderit, porro voluptates perferendis odit eaque recusandae! Itaque error aliquid, eum atque excepturi reiciendis fm, atque.
        </p>
        <p>
          ua molestias odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehenderit, porro voluptates perferendis odit eaque recusandae! Itaque error aliquid, eum atque excepturi reiciendis fu molestias odio maiores libero natus cumque amet impedit repellendus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti libero reprehenderit, porro voluptates perferendis odit eaque recusandae! Itaque error aliquid, eum atque excepturi reiciendis fuga cumque tenetur dolore! Reru
        </p>
        <br />
        <br />
        <br />
        <div className="back-button">
          <Link to={RoutePath.HOME_PAGE}><span className="link-arrow">&larr;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Home page</Link>
        </div>
      </div>
    </div>
  )
}

export default PolicyPage;