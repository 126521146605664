import { Mesh, Group, ExtrudeGeometry, SphereGeometry, MorphTarget } from "three";
import { UserMicrobeFrame } from "../../../dto/userMicrobeFrame";
import React, { MutableRefObject, useRef } from "react";
import { useFrame } from "react-three-fiber";
import { drawNormalMicrobe } from "./normalMicrobeSkeleton";
import { normalMicrobeParams } from "./normalMicrobeParams";
import { gameFrame } from "../../../staticRedux/gameFrames";

const angle = (cx: number, cy: number, ex: number, ey: number) => {
  var dy = ey - cy;
  var dx = ex - cx;
  var theta = Math.atan2(dy, dx); // range (-PI, PI]
  // theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
  return theta //* Math.PI / 180;
}

const NormalMicrobe: React.FC<any> = (props: any) => {

  let tempFrame =
    {
      microbeType: "normal",
      lifePoints: 50,
      stepX: 0.001,
      stepY: 0.001,
      servPosition: { x: 0, y: 0 },
      targetPosition: { x: 0, y: 0 }
    } as UserMicrobeFrame;

  const microbeBody: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();
  const microbeCore: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();
  const refMicrobe: MutableRefObject<Group | undefined> = useRef<THREE.Group>();
  // const reffPoint: MutableRefObject<Mesh | undefined> = useRef<THREE.Mesh>();

  let microbeShape = drawNormalMicrobe(0);
  let microbeShapeMorph = drawNormalMicrobe(1);

  let microbeShapeMorphGeom = new ExtrudeGeometry([microbeShapeMorph], normalMicrobeParams.extrudeSettings);
  let coreMicrobeMorphGeom = new SphereGeometry(0.13, 10, 10);

  let slowerAnim = 0;


  useFrame(() => {
    if (microbeBody.current && microbeCore.current && microbeBody.current!.morphTargetInfluences && microbeCore.current!.morphTargetInfluences && microbeCore.current!.morphTargetInfluences.length > 0 && microbeBody.current!.morphTargetInfluences.length > 0) {
      microbeBody.current!.morphTargetInfluences[0] = (Math.cos((slowerAnim / 15) * Math.PI * ((3 / 10) + 1)) + 1) / 2;
      microbeCore.current!.morphTargetInfluences[0] = (Math.cos((slowerAnim / 15) * Math.PI * ((3 / 10) + 1)) + 1) / 2;
    }

    if (microbeBody.current && microbeCore.current && gameFrame.microbes[props.id]) {
      // reffPoint.current!.position.set(gameFrame.microbes[props.id].targetPosition.x, gameFrame.microbes[props.id].targetPosition.y, 0);
      refMicrobe.current!.position.set(gameFrame.microbes[props.id].servPosition.x, gameFrame.microbes[props.id].servPosition.y, 0);
      refMicrobe.current!.rotation.z = angle(gameFrame.microbes[props.id].servPosition.x, gameFrame.microbes[props.id].servPosition.y, gameFrame.microbes[props.id].targetPosition.x, gameFrame.microbes[props.id].targetPosition.y)
    }
    slowerAnim++;
    microbeBody.current!.updateMatrix();
  })

  return (
    <>
      {/* <mesh key={props.id + "reffPoint"} ref={reffPoint} position={[tempFrame.targetPosition.x, tempFrame.targetPosition.y, 0]}>
        <sphereGeometry attach="geometry" args={[0.15, 10, 10]} />
        <meshNormalMaterial attach="material" />
      </mesh> */}
      <group key={props.id + "groupRef"} ref={refMicrobe}>
        {/* <pointLight args={[0xffffff, 0.1]} /> */}
        <mesh key={props.id + "microbeCore"} ref={microbeCore} morphTargetInfluences={[1]} position={[0, 0, 0]}>
          <sphereGeometry attach="geometry" args={[0.15, 10, 10]}
            morphTargets={[{ vertices: coreMicrobeMorphGeom.vertices }] as Array<MorphTarget>}
          />
          <meshPhongMaterial attach="material" args={[normalMicrobeParams.coreMaterialParams]} />
        </mesh>
        <mesh key={props.id + "microbeBody"} ref={microbeBody} morphTargetInfluences={[Math.random()]} position={[0, 0, 0]}>
          <extrudeGeometry
            attach="geometry"
            args={[[microbeShape], normalMicrobeParams.extrudeSettings]}
            morphTargets={[{ vertices: microbeShapeMorphGeom.vertices }] as Array<MorphTarget>}
          />
          <meshPhongMaterial attach="material" args={[normalMicrobeParams.bodyMaterialParams]} />
        </mesh>
      </group>
    </>
  )
}

export default NormalMicrobe;