import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiePolicyPopupReducer from './cookiePolicyPopup/cookiePolicyPopup.reducer';
import appStateReducer from './appState/appState.reducer';
import userReducer from './user/user.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cookiePolicyPopup', 'user']
}

const rootReducer = combineReducers({
  cookiePolicyPopup: cookiePolicyPopupReducer,
  user: userReducer,
  appState: appStateReducer
})

export default persistReducer(persistConfig, rootReducer);