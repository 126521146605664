import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./home-page-auth.scss";
import "./../home-page.scss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { signOut } from "../../../redux/user/user.actions";
import GameLauncher from "../../../components/game-launcher/game-launcher";

const HomePageAuth: React.FC<any> = (props: any & RouteComponentProps) => {
  const user = useSelector(selectCurrentUser, shallowEqual);
  const { email, login } = user;
  const dispatch = useDispatch();

  return (
    <div className="home-page home-page-auth">
      <div className="top-nav">
        <div className="topNavItem user">
          {email}
        </div>
        <div className="topNavItem signout" onClick={() => dispatch(signOut())}>
          Sign Out
        </div>
      </div>
      <div className="box">
        <div className="title">
          Hello, {login}!
        </div>
        <div className="subtitle">
          Are you ready for new adventure?
        </div>
        <GameLauncher />
      </div>
    </div>
  )
}

export default HomePageAuth;