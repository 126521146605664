import React, { MutableRefObject, useRef } from "react";
import { DataTexture, LuminanceFormat, UnsignedByteType, ClampToEdgeWrapping, MeshBasicMaterial, DoubleSide, LinearFilter } from "three";

import { getDistanceBetweenPoints } from "../../gameTools/randomInt";
import { useFrame } from "react-three-fiber";
import { gameFrame } from "../../staticRedux/gameFrames";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { totemRef } from "../totem/totemGroup";

export let planeRef: any;
export let planeRefOverlay: any;

const FogOfWar: React.FC<any> = () => {
  const meshBasicMeterial: MutableRefObject<MeshBasicMaterial | undefined> = useRef<THREE.MeshBasicMaterial>();
  const user = useSelector(selectCurrentUser, shallowEqual);

  let mask = [] as Array<number>;
  let pieceSize = 30;
  let multiplicator = 200 / pieceSize;
  // console.log('userId: ', user.userId)
  // console.log('totemUser: ', gameFrame.totems.filter(x => x.userId !== user.userId))
  let team: 0 | 1 | undefined = undefined;
  useFrame(() => {
    if (team === undefined) {
      if (gameFrame.totems.length > 0) {
        team = gameFrame.totems.filter(x => x.userId === user.userId)[0].team as 0 | 1;
        console.log(gameFrame.totems.filter(x => x.userId === user.userId)[0].team);
      }
    }
    // else {
    let maskTemp = [] as Array<number>;
    let iterator = 0;
    for (let y = -pieceSize; y < pieceSize; y++) {
      for (let x = -pieceSize; x < pieceSize; x++) {
        const tempX = (x * multiplicator) + (multiplicator / 2);
        const tempY = (y * multiplicator) + (multiplicator / 2);
        let lightIndicators = [];
        for (let i = 0; i < totemRef.length; i++) {
          if (gameFrame.totems[i].team === team) {
            lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: totemRef[i].current!.position.x, y: totemRef[i].current!.position.y }) - 25)
          }
        }
        if (team === 1) {
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 200, y: 0 }) - 100)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 150, y: 140 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 150, y: -140 }) - 50)

          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 80, y: 150 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 80, y: -150 }) - 50)
          
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 0, y: 150 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 0, y: -150 }) - 50)
        }
        else if (team === 0) {
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: -200, y: 0 }) - 100)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: -150, y: 140 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: -150, y: -140 }) - 50)

          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: -80, y: 150 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: -80, y: -150 }) - 50)
          
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 0, y: 150 }) - 50)
          lightIndicators.push(getDistanceBetweenPoints({ x: tempX, y: tempY }, { x: 0, y: -150 }) - 50)
        }

        if (lightIndicators.some(x => x <= 0)) {
          maskTemp.push(mask[iterator] > 0 ? mask[iterator] - 17 : 0);
        }
        else {
          maskTemp.push(mask[iterator] < 255 ? mask[iterator] + 17 : 255);
        }
        iterator++;
      }
    }
    mask = maskTemp;
    const dataTemp = new Uint8Array(maskTemp.length);
    //copy mask into the typed array
    dataTemp.set(maskTemp);
    //create the texture
    const textureTemp = new DataTexture(dataTemp, 2 * pieceSize, 2 * pieceSize, LuminanceFormat, UnsignedByteType);
    // texture.flipY = true;
    textureTemp.wrapS = ClampToEdgeWrapping;
    textureTemp.wrapT = ClampToEdgeWrapping;
    //it's likely that our texture will not have "power of two" size, meaning that mipmaps are not going to be supported on WebGL 1.0, so let's turn them off
    textureTemp.generateMipmaps = false;

    textureTemp.magFilter = LinearFilter;
    textureTemp.minFilter = LinearFilter;

    if (meshBasicMeterial.current) {
      meshBasicMeterial.current.alphaMap = textureTemp;
    }
    // }
  })

  return (
    <mesh position={[0, 0, 1]}>
      <planeBufferGeometry attach="geometry" args={[400, 400, 1, 1]} />
      <meshBasicMaterial attach="material" args={[{ color: 0x1f2233, alphaMap: undefined, side: DoubleSide, transparent: true, opacity: 1 }]} ref={meshBasicMeterial} />
    </mesh>
  )
}

export default FogOfWar;