import { store } from "../../redux/store";
import { callApi } from "../ApiCallService";
import { ApiMethodDto } from "../ApiMethodDto";
import { ApiPath } from "../ApiPath";
import { PositionDto } from "../../dto/position-dto";

// declare var Stripe: any;

// export class PointsController {
//   static async buyPointsAsync(packageType: PremiumTypeEnum): Promise<void> {
//     return new Promise(async (resolve) => {
//       const token = store.getState().user.token
//       const options = {
//         headers: { Authorization: token, "Content-Type": "application/json" },
//         method: "GET",
//         expectedStatus: 200,
//         params: { name: "packageType", value: packageType }
//       } as ApiMethodDto;

//       await callApi(options.method, ApiPath.payments_createCheckoutSession, options)
//         .then((res: any) => {
//           if (res.details.status === options.expectedStatus) {
//             const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
//             stripe.redirectToCheckout({
//               sessionId: res.data.id
//             })
//             resolve();
//           }
//         })
//     });
//   }
// }

export class ActionsController {
  static async AddFood(position: PositionDto): Promise<void> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token
      const options = {
        headers: { Authorization: token, "Content-Type": "application/json" },
        body: position,
        method: "POST",
        expectedStatus: 200,
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.actions_addFood, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
          //   game
          //   const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
          //   stripe.redirectToCheckout({
          //     sessionId: res.data.id
          //   })

          //   resolve();

          }
        })
    });
  }
  static async SetTotemTarget(position: PositionDto): Promise<void> {
    return new Promise(async (resolve) => {
      const token = store.getState().user.token
      const options = {
        headers: { Authorization: token, "Content-Type": "application/json" },
        body: position,
        method: "POST",
        expectedStatus: 200,
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.actions_setTotemTarget, options)
        .then((res: any) => {
          // if (res.details.status === options.expectedStatus) {
          //   game
          //   const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string)
          //   stripe.redirectToCheckout({
          //     sessionId: res.data.id
          //   })

          //   resolve();
          // }
        })
    });
  }
}