import React, { useState, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import "./sign-upin-form.scss";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import FormInputOval from "../../../../components/form-input-oval/form-input-oval.component";
import BtnCustomShadow from "../../../../components/btn-custom-shadow/btn-custom-shadow";
import { signUp } from "../../../../redux/user/user.actions";
import { SignUpDto } from "../../../../dto/sign-up-dto";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../../route-paths";

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [disableButton, setdisableButton] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser, shallowEqual);

  let timeoutContainer: any;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(signUp({ email: email, login: login, password: password } as SignUpDto));
    setdisableButton(true);

    timeoutContainer = window.setTimeout(() => {
      setdisableButton(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      window.clearInterval(timeoutContainer);
    };
  }, [])

  return (
    <form className="sign-upin-form sign-up-email-form" onSubmit={handleSubmit} >
      <FormInputOval
        name="login"
        type="login"
        label="Login"
        value={login}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setLogin(e.target.value)}
        required
      />
      <FormInputOval
        name="email"
        type="email"
        label="Email"
        value={email}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEmail(e.target.value)}
        required
      />
      <FormInputOval
        name="password"
        type="password"
        label="Password"
        value={password}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPassword(e.target.value)}
        required
      />
      <p>By submitting you declare that you have read and accepted <Link to={RoutePath.POLICY_PAGE}>Our Policy</Link></p>
      <BtnCustomShadow type="submit" disabled={(user && user.token) || disableButton}>PLAY</BtnCustomShadow>
    </form >
  )
}

export default SignUpForm;