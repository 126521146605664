import React, { useRef } from "react";
import { gamePageParams } from "../../../pages/game-page/gamePageParams";

export let planeRef: any;

const GameTerrain: React.FC<any> = () => {
  planeRef = useRef<THREE.Group>();

  return (
    <>
      <mesh position={[0, 0, -1]} ref={planeRef} receiveShadow={true}>
        <boxGeometry attach="geometry" args={[400, 400, 0]} />
        <meshPhongMaterial attach="material" args={[gamePageParams.planeMaterialParams]} />
      </mesh>
    </>
  )
}

export default GameTerrain;